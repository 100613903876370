.dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .dialog-content {
    background-color: white;
    color:black;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }
 
.dialog-category {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .dialog-content-category {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }
 