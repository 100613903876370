* {
    box-sizing: border-box;
  }
  
  body {
    margin: 0;
  }
  
  .title {
    max-width: 400px;
    margin: auto;
    text-align: center;
    font-family: "Poppins", sans-serif;
  }
  .title h3 {
    font-weight: bold;
  }
  .title p {
    font-size: 12px;
    color: #118a44;
  }
  .title p.msg {
    color: initial;
    text-align: initial;
    font-weight: bold;
  }
  
  .otp-input-fields {
    margin: auto;
    background-color: white;
    box-shadow: 0px 0px 8px 0px #02025044;
    max-width: 400px;
    width: auto;
    display: flex;
    justify-content: center;
    gap: 10px;
    padding: 40px;
  }
  .otp-input-fields input {
    height: 40px;
    width: 40px;
    background-color: transparent;
    border-radius: 4px;
    border: 1px solid #2f8f1f;
    text-align: center;
    outline: none;
    font-size: 16px;
    /* Firefox */
  }
  .otp-input-fields input::-webkit-outer-spin-button, .otp-input-fields input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .otp-input-fields input[type=number] {
    -moz-appearance: textfield;
  }
  .otp-input-fields input:focus {
    border-width: 2px;
    border-color: #287a1a;
    font-size: 20px;
  }
  
  .result {
    max-width: 400px;
    margin: auto;
    padding: 24px;
    text-align: center;
  }
  .result p {
    font-size: 24px;
    font-family: "Antonio", sans-serif;
    opacity: 1;
    transition: color 0.5s ease;
  }
  .result p._ok {
    color: green;
  }
  .result p._notok {
    color: red;
    border-radius: 3px;
  }

  .otp-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f8f9fa;
  }
  
  h3 {
    margin-bottom: 20px;
    color: #333;
    font-size: 24px;
  }
  
  .otp-inputs {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .otp-input {
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: 18px;
    border: 2px solid #ddd;
    border-radius: 5px;
    transition: border-color 0.3s, box-shadow 0.3s;
  }
  
  .otp-input:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    outline: none;
  }
  
  .verify-btn {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .verify-btn:hover {
    background-color: #0056b3;
  }
  